import { ConnectApi } from '@js/plugins/connectApi';
import { documentReady, getNodeIndex, qs } from '@utils/toolbox';

window.dataLayer = window.dataLayer || [];

export const trackEvent = {
	fakeEcommerce: function (showresultID, outclickXOffset, OTA, price, recommendedArrayRightToLeft, peopleCount, airlineCode, trips, eventId) {
		let recommendedArray = '';
		const timeStamp = new Date().getTime().toString();

		for (let i = 0; i < recommendedArrayRightToLeft.length; i++) {
			recommendedArray += i + (recommendedArrayRightToLeft[i] ? 'y' : 'n');
		}

		let sku = '';
		let segments = 0;

		let ac = 0;

		for (let i = 0; i < trips.length; i++) {
			sku += airlineCode[ac] + '_' + trips[i].join('_');

			ac += (trips[i].length) / 2;

			if (i + 1 < trips.length) {
				sku += '-';
			}

			segments += trips[i].length / 2;
		}

		sku += '_' + segments;

		sku = sku.toUpperCase();

		const data = {
			event: 'GAecommerce',
			eventId: eventId,
			transactionId: showresultID + '_t' + timeStamp, // '74880110_pos4' - unique ID Comibine showresult_ID with the position of the flight search results list (Vertical)
			transactionAffiliation: OTA, // 'Ticket.se'     - OTA
			transactionTotal: Number(Math.round(price) + '.00'), // '4800.00'       - Total Ticket cost
			transactionProducts: [
				{
					sku: sku, // 'SU_ARL_NYC_LAX_ARL_8'  - Airline & Airports & Total Number of segments
					name: recommendedArray, // '1y2n3n4y5y6n7n8n9n10y' - Green or not green field in the results
					category: outclickXOffset.toString(), // '1'                     - Position of the link clicked in the OTA results list (horizontal)
					price: Number(Math.round(price / peopleCount) + '.00'), // '2400.00'               - Ticket cost pp
					quantity: Number(peopleCount) // 2                       - Number of people travelling
				}
			]
		};

		ConnectApi('Traktor', 'wheel', [data]);

		window.dataLayer.push(data);
	},
	socialInteraction: function (network, action, target) {
		if (typeof target === 'undefined') {
			target = 'www.flygeresor.se';
		}

		const data = {
			event: 'socialInt',
			socialAction: action,
			socialNetwork: network,
			socialTarget: target
		};

		window.dataLayer.push(data);
	},
	calendarChangeDate: function (type) {
		if (typeof type === 'undefined') {
			type = 'openjaw';
		}

		const data = {
			event: 'DatepickerChangeDate',
			datepickerAction: type
		};

		window.dataLayer.push(data);
	},
	showResultMoreInfoClicked: function () {
		const data = {
			event: 'ShowResultMoreInfo'
		};

		window.dataLayer.push(data);
	},
	cityClick: function (linkType, city, offset, FromIata) // Billigast Resor @ index & Destinationer
	{
		if (typeof FromIata === 'undefined') {
			FromIata = 'DEST';
		}

		const data = {
			event: 'GAevent',
			gaCategory: 'Cheapest_travel_' + FromIata,
			gaAction: 'Click_' + linkType + '_' + offset,
			gaLabel: city
		};

		window.dataLayer.push(data);
	},
	popularDestinationClick: function (linkType, name, offset) // Mest sökta
	{
		const data = {
			event: 'GAevent',
			gaCategory: 'Most_popular_destinations',
			gaAction: 'Click_' + linkType + '_' + offset,
			gaLabel: name
		};

		window.dataLayer.push(data);
	},
	latestSearchClick: function (from, to) // Senast sökta
	{
		const data = {
			event: 'GAevent',
			gaCategory: 'Latest_Searches',
			gaAction: 'Click',
			gaLabel: from + '-' + to
		};

		window.dataLayer.push(data);
	},
	showresultFilterChange: function (type, label) {
		const data = {
			event: 'GAevent',
			gaCategory: type ? 'viewType' : 'sortType',
			gaAction: 'Click',
			gaLabel: label
		};

		window.dataLayer.push(data);
	},
	searchCompleted: function () {
		window.dataLayer.push({ event: 'searchCompleted' });
	},
	searchInfo: function (children, adults, from, to, leave, home, cId, price, airportData) {
		const fromto = from + ' - ' + to;
		const data = {
			event: 'searchInfo',
			qcCustomer: {
				qcChildren: children,
				qcAdults: adults
			},
			qcSubchannel: {
				qcFromto: fromto
			},
			fbSearch_string: 'from ' + from + ' to ' + to + ' leave ' + leave + ' home ' + home + ' adults ' + adults + ' children ' + children,
			fbContent_category: 'Product Search',
			fbBrand: from,
			fbProduct_type: to,
			fbContent_ids: [cId],
			fbValue: price,
			fbContentName: fromto,
			airportData: airportData

		};
		window.dataLayer.push(data);
	},
	triggerGAevent: function (type, label, action = 'Click', value = 0) {
		if (typeof label === 'undefined') {
			label = type;
		}

		const data = {
			event: 'GAevent',
			gaCategory: type,
			gaAction: action,
			gaLabel: label,
			gaValue: value
		};

		window.dataLayer.push(data);
	},
	destinationInfo: function (country, city, pciCountry, pciCity) {
		const data = {
			event: 'productInfo',
			productCountry: country,
			productCity: city,
			productCatalogIdCountry: pciCountry,
			productCatalogIdCity: pciCity
		};
		window.dataLayer.push(data);
	},
	searchFieldIataSelected ({ segment = 'A', origin = 'AIRPORT', bound = 'FROM', keystrokes = 0 }) {
		const type = (segment == 'B' ? 'NewMobile ' : '') + 'Search Box ' + window.js_params.controllerClass.substr(5);
		const label = (bound + '-' + origin).toUpperCase();
		const action = 'Searchfield iata selected';
		const value = keystrokes;
		this.triggerGAevent(type, label, action, value);
	}
};

documentReady(function () {
	qs('ul#most-searched-list li.list-item a.list-link')?.addEventListener?.('click', function (e) {
		const target = e.target;

		const linkType = target.classList.contains('city') ? 'City' : 'Country';
		const linkName = target.dataset.name;
		const offset = getNodeIndex(target.parentElement);

		trackEvent.popularDestinationClick(linkType, linkName, offset);
	});

	qs('#most-searched a.most-searched__item')?.addEventListener('click', function (e) {
		const target = e.target;

		const linkType = 'most-searched';
		const linkName = target.attr('href');
		const patt = new RegExp('[0-9]?[0-9]');
		const offset = patt.exec(target.innerText);

		trackEvent.popularDestinationClick(linkType, linkName, offset);
	});

	qs('div#latest-searches')?.addEventListener?.('click', function (e) {
		const target = e.target.closest('a.search');
		if (target) {
			const { from, to } = target.dataset;
			trackEvent.latestSearchClick(from, to);
		}
	});

	qs('div#cheapest-destinations ul.list a.list-link')?.addEventListener?.('click', function (e) {
		const target = e.target;
		const parent = target.parentElement;

		const linkType = target.classList.contains('destination') ? 'City' : 'Price';
		const { from, to, city } = parent.dataset;

		const offset = getNodeIndex(parent);

		// linkType, city, offset, FromIata
		trackEvent.cityClick(linkType, city, offset, from + '_' + to);
	});

	// Footer social links(gplus/facebook/twitter)
	qs('.social-media-links .social-media-links__link')?.addEventListener?.('click', function (e) {
		const target = e.target;
		let network;

		if (target.classList.contains('facebook')) {
			network = 'facebook';
		} else if (target.classList.contains('twitter')) {
			network = 'twitter';
		} else if (target.classList.contains('instagram')) {
			network = 'instagram';
		}

		trackEvent.socialInteraction(network, 'link', target.href);
	});

	// Showresult sort type / view type change.
	qs('#menu-sorting .menu-list-item, #menu-view .menu-list-item')?.addEventListener?.('click', function (e) {
		const target = e.target;
		if (target.classList.contains('sort-type')) {
			if (target.classList.contains('cheapest')) {
				trackEvent.showresultFilterChange(0, 'Cheapest');
			} else if (target.classList.contains('fastest')) {
				trackEvent.showresultFilterChange(0, 'Fastest');
			} else if (target.classList.contains('best')) {
				trackEvent.showresultFilterChange(0, 'Best');
			} else if (target.classList.contains('emission')) {
				trackEvent.showresultFilterChange(0, 'Emission');
			}
		} else if (target.classList.contains('view-type')) {
			if (target.classList.contains('list-view')) {
				trackEvent.showresultFilterChange(1, 'List');
			} else if (target.classList.contains('timetable-view')) {
				trackEvent.showresultFilterChange(1, 'Timetable');
			} else if (target.classList.contains('timeline-view')) {
				trackEvent.showresultFilterChange(1, 'Timeline');
			}
		}
	});
});
